<template>
  <div :class="{ 'row': !fullWidth }">
    <WalletsList class="" :collSizeWallet="linkedObjs.length == maxWallet && !fullWidth ? 'col-6' : 'col-12'" @model-detach="detachWallet" :showTitle="false" v-model="value" :wallets="linkedObjs" v-if="linkedObjs.length" />
    <div :class="{ 'add-wallet-card': true, 'col-6': !fullWidth, 'q-pl-sm': true }" v-if="linkedObjs.length < maxWallet">
      <search-box v-if="searchBoxActivated"
        @input="onSelectLinkedObj"
        model_type="wallet"
        :label="$t('processes.fields.wallet.label')"
        :params="{ 'with_virtual_objects': true, 'with_deleted_objects': true, 'pdv': pdv }"
        :color="color" />
      <q-btn icon="add_circle" v-else :color="color" class="full-height full-width" size="md" outline @click="addObject">
        {{$t('processes.link_wallet')}}
      </q-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { WalletsList } from '../../../components/wallets'
export default {
  props: ['wallets', 'formData', 'value', 'max', 'fullWidth', 'pdv'],
  components: {
    WalletsList
  },
  data () {
    return {
      searchBoxActivated: false,
      linkedObjs: []
    }
  },
  watch: {
    wallets: {
      handler (newVal, oldVal) {
        this.linkedObjs = this.wallets
          ? this.wallets.map(wallet => ({
            ...wallet,
            'value.actions': [
              { label: this.$t('tasks.remove_linked_object'), payload: { name: 'model-detach', params: { id: wallet.id } } }
            ] }))
          : []
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      color: 'pages/getColor'
    }),
    maxWallet () {
      return this.max ? this.max : 500
    }
  },
  mounted () {
    this.linkedObjs = this.wallets
      ? this.wallets.map(wallet => ({
        ...wallet,
        'value.actions': [
          { label: this.$t('tasks.remove_linked_object'), payload: { name: 'model-detach', params: { id: wallet.id } } }
        ] }))
      : []
  },
  methods: {
    detachWallet (walletId) {
      this.linkedObjs = this.linkedObjs.filter(obj => obj.id !== walletId)
      this.$emit('walletsUpdated', this.linkedObjs)
    },
    onSelectLinkedObj (obj) {
      console.log(obj)
      this.searchBoxActivated = false
      this.linkedObjs.push({
        id: obj.value.value,
        NOMPTF: obj.value.label,
        'value.actions': [
          { label: this.$t('tasks.remove_linked_object'), payload: { name: 'model-detach', params: { id: obj.value.value } } }
        ]
      })
      this.$emit('walletsUpdated', this.linkedObjs)
    },
    activateSearchBox () {
      this.searchBoxActivated = true
    },
    addObject () {
      this.activateSearchBox()
    }
  }
}
</script>

<style lang="stylus" scoped>
  .add-wallet-card
    height 228px
</style>
