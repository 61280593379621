<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <div class="q-mb-sm">
        <h6 class="title-h6">{{$t('processes.agent')}}</h6>
        <div class="row">
          <AgentCard class="col-6" icon="accessibility" :showSeparator="false" v-if="linkedAgent" :agent="linkedAgent" @menuClick="onMenuClick" />
          <div class="q-pl-sm add-agent-card col-6" v-else>
            <search-box v-if="searchBoxActivated"
              @input="onSelectLinkedObj"
              model_type="agent"
              :label="$t('processes.fields.agent.label')"
              :params="{ 'with_virtual_objects': true, 'with_deleted_objects': true }"
              :color="color" />
            <base-add-button v-else :label="$t('processes.add_agent')" :color="color" @click="searchBoxActivated = true" />
          </div>
        </div>
      </div>
      <div>
        <h6 class="title-h6">{{$t('processes.wallet')}}</h6>
        <div class="row">
          <add-a-wallet :max="1" v-if="currentSuiviIcProcess && currentSuiviIcProcess.wallets" @walletsUpdated="updateWallet" v-model="currentSuiviIcProcess.wallets" :wallets="currentSuiviIcProcess.wallets" class="col"/>
          <add-a-wallet :max="1" v-else @walletsUpdated="updateWallet" class="col"/>
        </div>
      </div>
      <div class="bloc-item q-mt-md q-mb-sm">
        <q-card flat bordered class="q-pa-sm collapsible-div">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row q-gutter-sm">
              <base-input-select class="col q-pa-none q-mt-none" placeholder="Renseigner" v-bind="formInputProps('nature')" :color="color"
                v-model="formData.nature" ref="label" />
              <base-input-date class="col" placeholder="Remplir" v-bind="formInputProps('date_evenement')" :color="color"
                v-model="formData.date_evenement" ref="label" />
            </div>
           <div class="row q-gutter-sm">
              <base-input-select class="col q-pa-none q-mt-none" placeholder="Renseigner" v-bind="formInputProps('statut_initial_compte_gestion')" :color="color"
                v-model="formData.statut_initial_compte_gestion" ref="label" />
              <base-input-date class="col" placeholder="Remplir" v-bind="formInputProps('date_validation')" :color="color"
                v-model="formData.date_validation" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-text class="col" placeholder="Renseigner" v-bind="formInputProps('position_compte')" :color="color"
                v-model="formData.position_compte" ref="label" />
              <base-input-text class="col" placeholder="Remplir" v-bind="formInputProps('statut_agent')" :color="color"
                v-model="formData.statut_agent" ref="label" />
            </div>
            <q-item-label class="q-pt-sm q-pl-sm">{{$t('processes.fields.motif_ko.label')}}</q-item-label>
            <base-input-text class="q-px-sm row" v-bind="formInputProps('motif_ko')" label="" placeholder="Renseigner" :color="color"
              v-model="formData.motif_ko" ref="label" />
          </q-card-section>
        </q-card>
      </div>
      <div>
        <h6 class="title-h6">{{$t('processes.iardSante')}}</h6>
        <q-card flat bordered class="q-pa-sm">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_demande_listing_yard')" :color="color"
                v-model="formData.date_demande_listing_yard" />
              <base-input-date class="col" v-bind="formInputProps('date_validation_calcul_yard')" :color="color"
                v-model="formData.date_validation_calcul_yard" ref="label" />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div>
        <h6 class="title-h6">{{$t('processes.icVie')}}</h6>
        <q-card flat bordered class="q-pa-sm">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_demande_calcul_ic')" :color="color"
                v-model="formData.date_demande_calcul_ic" />
              <base-input-date class="col" v-bind="formInputProps('date_validation_calcul_ic')" :color="color"
                v-model="formData.date_validation_calcul_ic" ref="label" />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item">
        <h6 class="title-h6 processColor">{{$t('processes.iard')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card v-if="formData.data_montant" flat bordered class="q-pa-sm">
            <q-card-section class="q-gutter-sm justify-center">
              <base-input-text class="col" label="Montant calculé" :readonly="true" placeholder="" :color="color"
                  :value="`${computedAmountIard || 0}€`" ref="label" />
              <div class="row">
                <div class="row col-6 q-gutter-sm">
                  <base-input-text class="col" label="IC Classique" @input="changeComputedAmount('Iard')" placeholder="Remplir" :color="color"
                    v-model="formData.data_montant[0]['classique']" />
                  <base-input-text class="col" label="IC Complémentaire" @input="changeComputedAmount('Iard')" placeholder="Remplir"  :color="color"
                    v-model="formData.data_montant[0]['complementaire']" ref="label" />
                </div>
              </div>
            </q-card-section>
          </q-card>
          <h6 class="title-h6 q-mb-none">{{$t('processes.montant_iard')}}</h6>
          <div v-for="montantPropose in montantProposesIard.filter(montant => montant.date)" :key="montantPropose.id" class="row col-12 items-end">
            <base-input-text class="col q-pb-none" label="" :readonly="true" :color="color"
              :value="`IC Classique: ${parseFloat(montantPropose.classique || 0).toLocaleString()}€`" />
            <base-input-text class="col q-pb-none" label="" :readonly="true" placeholder="IC Complémentaire: X.XXX.XXX€" :color="color"
              :value="`IC Complémentaire: ${parseFloat(montantPropose.complementaire || 0).toLocaleString()}€`" ref="label" />
            <base-input-text class="col col-2 q-pb-none" label="" :readonly="true" placeholder="Date" :color="color"
                :value="$formatDate(montantPropose.date)" />
            <base-input-text class="col col-3 q-pb-none" label="" :readonly="true" :color="color"
              :value="booleanOption.find(option => option.value === montantPropose.accepte_agent) ? booleanOption.find(option => option.value === montantPropose.accepte_agent).label : null" />
            <q-icon class="col-1 q-pb-sm" name="delete_forever" @click="removeMontant('Iard', montantPropose.id)" size="2rem" />
          </div>
          <div class="row col-12 items-end">
            <base-input-text class="col q-pb-none" label="" placeholder="IC Classique: X.XXX.XXX€" :color="color"
              v-model="icClassiqueIardAmount" />
            <base-input-text class="col q-pb-none" label="" placeholder="IC Complémentaire: X.XXX.XXX€" :color="color"
              v-model="icComplementaireIardAmount" ref="label" />
            <div class="fix-padding q-pb-none col-2 q-field--with-bottom">
              <base-input-date label="" placeholder="Date" :color="color"
                v-model="icDateIard" />
            </div>
            <div class="col-3">
              <base-input-select :options="booleanOption" placeholder="Accepté par l'agent" type="select" class="q-pb-none" label="" :color="color"
                v-model="icAcceptAgentIard" />
            </div>
            <div class="col-1"></div>
          </div>
          <div class="row justify-end">
            <div class="col col-3 q-py-sm">
              <q-btn class="full-width justify-between" color="process" size="sm" :disabled="!isUpdate" @click="addMontant('Iard')" icon-right="add_circle">{{$t('processes.enregistrer')}}</q-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="bloc-item">
        <h6 class="title-h6 processColor">{{$t('processes.sante')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card v-if="formData.data_montant" flat bordered class="q-pa-sm">
            <q-card-section class="q-gutter-sm justify-center">
              <base-input-text class="col" label="Montant calculé" :readonly="true" placeholder="" :color="color"
                :value="`${computedAmountSante || 0}€`" />
              <div class="row">
                <div class="row col-6 q-gutter-sm">
                  <base-input-text class="col" label="IC Classique" @input="changeComputedAmount('Sante')" placeholder="Remplir" :color="color"
                    v-model="formData.data_montant[1]['classique']" />
                  <base-input-text class="col" label="IC Complémentaire" @input="changeComputedAmount('Sante')" placeholder="Remplir"  :color="color"
                    v-model="formData.data_montant[1]['complementaire']" />
                </div>
              </div>
            </q-card-section>
          </q-card>
          <h6 class="title-h6 q-mb-none">{{$t('processes.montant_sante')}}</h6>
          <div v-for="montantPropose in montantProposesSante.filter(montant => montant.date)" :key="montantPropose.id" class="row col-12 items-end">
            <base-input-text class="col q-pb-none" label="" placeholder="IC Classique: X.XXX.XXX€" :readonly="true" :color="color"
              :value="`IC Classique: ${parseFloat(montantPropose.classique || 0).toLocaleString()}€`" />
            <base-input-text class="col q-pb-none" label="" placeholder="IC Complémentaire: X.XXX.XXX€" :readonly="true" :color="color"
              :value="`IC Complémentaire: ${parseFloat(montantPropose.complementaire).toLocaleString()}€`" ref="label" />
            <base-input-text class="col col-2 q-pb-none" label="" placeholder="Date" :readonly="true" :color="color"
                :value="$formatDate(montantPropose.date)" />
            <base-input-text class="col col-3 q-pb-none" label="" placeholder="Accepté par l'agent" :readonly="true" :color="color"
              :value="booleanOption.find(option => option.value === montantPropose.accepte_agent) ? booleanOption.find(option => option.value === montantPropose.accepte_agent).label : null" />
            <q-icon class="col-1 q-pb-sm" name="delete_forever" @click="removeMontant('Sante', montantPropose.id)" size="2rem" />
          </div>
          <div class="row col-12 items-end">
            <base-input-text class="col q-pb-none" label="" placeholder="IC Classique: X.XXX.XXX€" :color="color"
              v-model="icClassiqueSanteAmount" />
            <base-input-text class="col q-pb-none" label="" placeholder="IC Complémentaire: X.XXX.XXX€" :color="color"
              v-model="icComplementaireSanteAmount" ref="label" />
            <div class="fix-padding q-pb-none col-2 q-field--with-bottom">
              <base-input-date label="" placeholder="Date" :color="color"
                v-model="icDateSante" />
            </div>
            <div class="col-3">
              <base-input-select :options="booleanOption" placeholder="Accepté par l'agent" type="select" class="q-pb-none" label="" :color="color"
                v-model="icAcceptAgentSante" />
            </div>
            <div class="col-1"></div>
          </div>
          <div class="row justify-end">
            <div class="col col-3 q-py-sm">
              <q-btn class="full-width justify-between" color="process" size="sm" :disabled="!isUpdate" @click="addMontant('Sante')" icon-right="add_circle">{{$t('processes.enregistrer')}}</q-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="bloc-item">
        <h6 class="title-h6 processColor">{{$t('processes.ic_vie')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card v-if="formData.data_montant" flat bordered class="q-pa-sm">
            <q-card-section class="q-gutter-sm justify-center">
              <base-input-text class="col" label="Montant calculé" :readonly="true" placeholder="" :color="color"
                  :value="`${computedAmountIc_vie || 0}€`" ref="label" />
              <div class="row">
                <div class="row col-6 q-gutter-sm">
                  <base-input-text class="col" label="IC Classique" @input="changeComputedAmount('Ic_vie')" placeholder="Remplir" :color="color"
                    v-model="formData.data_montant[2]['classique']" />
                  <base-input-text class="col" label="IC Complémentaire" @input="changeComputedAmount('Ic_vie')" placeholder="Remplir"  :color="color"
                    v-model="formData.data_montant[2]['complementaire']" ref="label" />
                </div>
              </div>
            </q-card-section>
          </q-card>
          <h6 class="title-h6 q-mb-none">{{$t('processes.montant_ic_vie')}}</h6>
          <div v-for="montantPropose in montantProposesIc_vie.filter(montant => montant.date)" :key="montantPropose.id" class="row col-12 items-end">
            <base-input-text class="col q-pb-none" label="" placeholder="IC Classique: X.XXX.XXX€" :readonly="true" :color="color"
              :value="`IC Classique: ${parseFloat(montantPropose.classique || 0).toLocaleString()}€`" />
            <base-input-text class="col q-pb-none" label="" placeholder="IC Complémentaire: X.XXX.XXX€" :readonly="true" :color="color"
              :value="`IC Complémentaire: ${parseFloat(montantPropose.complementaire || 0).toLocaleString()}€`" ref="label" />
            <base-input-text class="col col-2 q-pb-none" label="" placeholder="Date" :readonly="true" :color="color"
                :value="$formatDate(montantPropose.date)" />
            <base-input-text class="col col-3 q-pb-none" label="" placeholder="Accepté par l'agent" :readonly="true" :color="color"
              :value="booleanOption.find(option => option.value === montantPropose.accepte_agent) ? booleanOption.find(option => option.value === montantPropose.accepte_agent).label : null" />
            <q-icon class="col-1 q-pb-sm" name="delete_forever" @click="removeMontant('Ic_vie', montantPropose.id)" size="2rem" />
          </div>
          <div class="row col-12 items-end">
            <base-input-text class="col q-pb-none" label="" placeholder="IC Classique: X.XXX.XXX€" :color="color"
              v-model="icClassiqueIc_vieAmount" />
            <base-input-text class="col q-pb-none" label="" placeholder="IC Complémentaire: X.XXX.XXX€" :color="color"
              v-model="icComplementaireIc_vieAmount" ref="label" />
            <div class="fix-padding q-pb-none col-2 q-field--with-bottom">
              <base-input-date label="" placeholder="Date" :color="color"
                v-model="icDateIc_vie" />
            </div>
            <div class="col-3">
              <base-input-select :options="booleanOption" placeholder="Accepté par l'agent" type="select" class="q-pb-none" label="" :color="color"
                v-model="icAcceptAgentIc_vie" />
            </div>
            <div class="col-1"></div>
          </div>
          <div class="row justify-end">
            <div class="col col-3 q-py-sm">
              <q-btn class="full-width justify-between" color="process" size="sm" :disabled="!isUpdate" @click="addMontant('Ic_vie')" icon-right="add_circle">{{$t('processes.enregistrer')}}</q-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="bloc-item">
        <h6 class="title-h6 processColor">{{$t('processes.differentiel_ic')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card flat bordered class="q-pa-sm">
            <q-card-section class="q-gutter-sm justify-center">
              <base-input-text class="col" label="Montant calculé" placeholder="Remplir" :color="color"
                  v-model="formData.data_montant[3]['classique']" ref="label" />
            </q-card-section>
          </q-card>
          <h6 class="title-h6 q-mb-none">{{$t('processes.montant_differentiel_ic')}}</h6>
          <div v-for="montantPropose in montantProposesDifferentiel_ic" :key="montantPropose.id" class="row col-12 items-end">
            <base-input-text class="col q-pb-none" label="" placeholder="Montant: X.XXX.XXX€" :readonly="true" :color="color"
              :value="`Montant: ${parseFloat(montantPropose.classique || 0).toLocaleString()}€`" />
            <base-input-text class="col col-2 q-pb-none" label="" placeholder="Date" :readonly="true" :color="color"
                :value="$formatDate(montantPropose.date)" />
            <base-input-text class="col col-3 q-pb-none" label="" placeholder="Accepté par l'agent" :readonly="true" :color="color"
              :value="booleanOption.find(option => option.value === montantPropose.accepte_agent) ? booleanOption.find(option => option.value === montantPropose.accepte_agent).label : null" />
            <q-icon class="col-1 q-pb-sm" name="delete_forever" @click="removeMontant('Differenctiel_ic', montantPropose.id)" size="2rem" />
          </div>
          <div class="row col-12 items-end">
            <base-input-text class="col q-pb-none" label="" placeholder="Montant: X.XXX.XXX€" :color="color"
              v-model="icClassiqueDifferentiel_icAmount" />
            <div class="fix-padding q-pb-none col-2 q-field--with-bottom">
              <base-input-date label="" placeholder="Date" :color="color"
                v-model="icDateDifferentiel_ic" />
            </div>
            <div class="col-3">
              <base-input-select :options="booleanOption" placeholder="Accepté par l'agent" type="select" class="q-pb-none" label="" :color="color"
                v-model="icAcceptAgentDifferentiel_ic" />
            </div>
            <div class="col-1"></div>
          </div>
          <div class="row justify-end">
            <div class="col col-3 q-py-sm">
              <q-btn class="full-width justify-between" color="process" size="sm" :disabled="!isUpdate" @click="addMontant('Differentiel_ic')" icon-right="add_circle">{{$t('processes.enregistrer')}}</q-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="bloc-item">
        <h6 class="title-h6 processColor">{{$t('processes.sinistre_reorganisation')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card flat bordered class="q-pa-sm">
            <q-card-section class="q-gutter-sm justify-center">
              <base-input-text class="col" label="Montant calculé" placeholder="Remplir" :color="color"
                  v-model="formData.data_montant[4]['classique']" ref="label" />
            </q-card-section>
          </q-card>
          <h6 class="title-h6 q-mb-none">{{$t('processes.montant_sinistre_reorganisation')}}</h6>
          <div v-for="montantPropose in montantProposesSinistre_reorganisation" :key="montantPropose.id" class="row col-12 items-end">
            <base-input-text class="col q-pb-none" label="" placeholder="Montant: X.XXX.XXX€" :readonly="true" :color="color"
              :value="`Montant: ${parseFloat(montantPropose.classique || 0).toLocaleString()}€`" />
            <base-input-text class="col col-2 q-pb-none" label="" placeholder="Date" :readonly="true" :color="color"
                :value="$formatDate(montantPropose.date)" />
            <base-input-text class="col col-3 q-pb-none" label="" placeholder="Accepté par l'agent" :readonly="true" :color="color"
              :value="booleanOption.find(option => option.value === montantPropose.accepte_agent) ? booleanOption.find(option => option.value === montantPropose.accepte_agent).label : null" />
            <q-icon class="col-1 q-pb-sm" name="delete_forever" @click="removeMontant('Sinistre_reorganisation', montantPropose.id)" size="2rem" />
          </div>
          <div class="row col-12 items-end">
            <base-input-text class="col q-pb-none" label="" placeholder="Montant: X.XXX.XXX€" :color="color"
              v-model="icClassiqueSinistre_reorganisationAmount" />
            <div class="fix-padding q-pb-none col-2 q-field--with-bottom">
              <base-input-date label="" placeholder="Date" :color="color"
                v-model="icDateSinistre_reorganisation" />
            </div>
            <div class="col-3">
              <base-input-select :options="booleanOption" placeholder="Accepté par l'agent" type="select" class="q-pb-none" label="" :color="color"
                v-model="icAcceptAgentSinistre_reorganisation" />
            </div>
            <div class="col-1"></div>
          </div>
          <div class="row justify-end">
            <div class="col col-3 q-py-sm">
              <q-btn class="full-width justify-between" color="process" size="sm" :disabled="!isUpdate" @click="addMontant('Sinistre_reorganisation')" icon-right="add_circle">{{$t('processes.enregistrer')}}</q-btn>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h6 class="title-h6">{{$t('processes.linked_processes')}}</h6>
        <div class="row q-col-gutter-md">
          <add-a-process class="" :color="color"/>
        </div>
      </div>
    </div>

    <div class="col col-2 q-gutter-y-sm">
      <q-list class="full-width">
        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('statut')" :color="color"
            v-model="formData.statut" ref="statut" />
        </q-item>
      </q-list>

      <div class="q-gutter-y-sm">
        <q-btn :color="color" class="full-width" size="sm" outline @click.native="destroy" :disabled="!isUpdate">{{$t('form.delete')}}</q-btn>
        <q-btn :color="color" class="full-width" size="sm" unelevated type="submit" :disabled="!hasChanged">{{$t('form.submit')}}</q-btn>
        <q-btn :color="color" class="full-width q-py-md" size="sm" @click="finishProcess" :disabled="!isUpdate">{{$t('form.finish')}}</q-btn>
      </div>

    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseForm } from '../../../mixins'
import { AgentCard } from '../../../components/agents'
import addAWallet from '../shared/addAWallet'
import addAProcess from '../shared/addAProcess'

export default {
  mixins: [BaseForm],
  components: { AgentCard, addAWallet, addAProcess },
  props: {
    'form_model': { type: String, default: 'processes' },
    'action': { type: String, default: 'processes/saveProcesses' }
  },

  data () {
    return {
      filter: {},
      isUpdate: this.$route.params.id,
      searchBoxActivated: false,
      linkedAgent: null,
      icClassiqueIardAmount: null,
      icComplementaireIardAmount: null,
      icDateIard: null,
      icAcceptAgentIard: null,
      icClassiqueSanteAmount: null,
      icComplementaireSanteAmount: null,
      icDateSante: null,
      icAcceptAgentSante: null,
      icClassiqueIc_vieAmount: null,
      icComplementaireIc_vieAmount: null,
      icDateIc_vie: null,
      icAcceptAgentIc_vie: null,
      icClassiqueDifferentiel_icAmount: null,
      icDateDifferentiel_ic: null,
      icAcceptAgentDifferentiel_ic: null,
      icClassiqueSinistre_reorganisationAmount: null,
      icComplementaireSinistre_reorganisationAmount: null,
      icDateSinistre_reorganisation: null,
      icAcceptAgentSinistre_reorganisation: null,
      computedAmountIard: 0,
      computedAmountSante: 0,
      computedAmountIc_vie: 0,

      booleanOption: [
        { value: true, label: 'Oui' },
        { value: false, label: 'Non' }
      ],
      montantProposes: []
    }
  },

  computed: {
    ...mapGetters({
      color: 'pages/getColor',
      isAdmin: 'auth/isAdmin',
      currentSuiviIcProcess: 'processes/getCurrentProcess',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    }),
    montantProposesIard () {
      return this.montantProposes.filter(montantPropose => montantPropose.domaine === 'IARD')
    },
    montantProposesSante () {
      return this.montantProposes.filter(montantPropose => montantPropose.domaine === 'SANTE')
    },
    montantProposesIc_vie () {
      return this.montantProposes.filter(montantPropose => montantPropose.domaine === 'IC_VIE')
    },
    montantProposesDifferentiel_ic () {
      return this.montantProposes.filter(montantPropose => montantPropose.domaine === 'DIFFERENTIEL_IC')
    },
    montantProposesSinistre_reorganisation () {
      return this.montantProposes.filter(montantPropose => montantPropose.domaine === 'SINISTRE_REORGANISATION')
    }
  },

  watch: {
    linkedAgent (newVal, oldVal) {
      this.formData.related_objects = this.formData.related_objects.filter(related => related.model_type !== 'agent')
      if (newVal) this.formData.related_objects.push({ model_type: 'agent', model_id: newVal.id })
    },

    formFields: {
      handler (newVal, oldVal) {
        this.formData.model_type = 'suiviIc'
        this.formData.related_objects = []
        this.formData.data_montant = [
          {
            classique: 0,
            complementaire: 0,
            domaine: 'IARD'
          },
          {
            classique: 0,
            complementaire: 0,
            domaine: 'SANTE'
          },
          {
            classique: 0,
            complementaire: 0,
            domaine: 'IC_VIE'
          },
          {
            classique: 0,
            complementaire: 0,
            domaine: 'DIFFERENTIEL_IC'
          },
          {
            classique: 0,
            complementaire: 0,
            domaine: 'SINISTRE_REORGANISATION'
          }
        ]
        if (!newVal || !this.currentSuiviIcProcess) return

        this.formData = {
          ...this.formData,
          ...newVal.map(({ id, options, type, boolean }) => ({ id, options, type, boolean }))
            .reduce((acc, { id, options, type, boolean }) => ({
              ...acc,
              [id]: options
                ? options.find(({ value }) => value === this.currentSuiviIcProcess[id])
                : type === 'select' && boolean
                  ? this.booleanOption.find(val => val.value === this.currentSuiviIcProcess[id])
                  : this.currentSuiviIcProcess[id]
            }), {})
        }

        if (this.$route.params.id) {
          this.formData.id = this.$route.params.id

          // Set agent
          if (this.currentSuiviIcProcess.agents) {
            this.linkedAgent = this.currentSuiviIcProcess.agents.map(agent => ({
              ...agent,
              label: agent['TITULAIRE'],
              actions: [{ label: this.$t('tasks.remove_linked_object'), payload: { name: 'model-detach', params: { id: agent.id } } }],
              is_favorite: !!this.favorites && !!this.favorites['agent'] &&
                this.favorites['agent'].some(favorite => favorite.id === agent.id),
              model: { model_type: 'agent', model_id: agent.id }
            }))[0]
          }

          // Set wallets data
          if (this.currentSuiviIcProcess.wallets) {
            let walletsMapped = this.currentSuiviIcProcess.wallets.map(wallet => ({
              model_type: 'wallet',
              model_id: wallet.id
            }))
            this.formData.related_objects.push(...walletsMapped)
          }
          // Montant propose
          this.montantProposes = this.currentSuiviIcProcess.montant_proposes || []
          this.formData.data_montant = [
            {
              classique: this.montantProposesIard.find(montant => !montant.date) ? this.montantProposesIard.find(montant => !montant.date).classique : 0,
              complementaire: this.montantProposesIard.find(montant => !montant.date) ? this.montantProposesIard.find(montant => !montant.date).complementaire : 0,
              domaine: 'IARD'
            },
            {
              classique: this.montantProposesSante.find(montant => !montant.date) ? this.montantProposesSante.find(montant => !montant.date).classique : 0,
              complementaire: this.montantProposesSante.find(montant => !montant.date) ? this.montantProposesSante.find(montant => !montant.date).complementaire : 0,
              domaine: 'SANTE'
            },
            {
              classique: this.montantProposesIc_vie.find(montant => !montant.date) ? this.montantProposesIc_vie.find(montant => !montant.date).classique : 0,
              complementaire: this.montantProposesIc_vie.find(montant => !montant.date) ? this.montantProposesIc_vie.find(montant => !montant.date).complementaire : 0,
              domaine: 'IC_VIE'
            },
            {
              classique: this.montantProposesDifferentiel_ic.find(montant => !montant.date) ? this.montantProposesDifferentiel_ic.find(montant => !montant.date).classique : 0,
              complementaire: 0,
              domaine: 'DIFFERENTIEL_IC'
            },
            {
              classique: this.montantProposesSinistre_reorganisation.find(montant => !montant.date) ? this.montantProposesSinistre_reorganisation.find(montant => !montant.date).classique : 0,
              complementaire: 0,
              domaine: 'SINISTRE_REORGANISATION'
            }
          ]
          this.computedAmountIard = this.getComputedAmount(0)
          this.computedAmountSante = this.getComputedAmount(1)
          this.computedAmountIc_vie = this.getComputedAmount(2)
        }
      },
      immediate: true
    }
  },

  mounted () {
    this.$store.commit('pages/setTabs', this.$route.params.id
      ? [
        { name: 'suivi-des-ic-form', label: 'Informations', to: { name: 'suivi-des-ic-form', params: { id: this.$route.params.id } } },
        { name: 'echeance-fiche', label: 'échéances & paiements', to: { name: 'echeance-fiche', params: { suiviDesIcId: this.$route.params.id } } }
      ] : [
        { name: 'suivi-des-ic-form', label: 'Informations', to: { name: 'suivi-des-ic-form', params: { id: this.$route.params.id } } }
      ])
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('processes.processAgence'), to: { name: '' } },
      { label: this.$t('processes.title.suivi_des_ic'), to: { name: 'suivi-des-ic-en-cours' } },
      this.$route.params.id ? { label: `fiche #${this.$route.params.id}`, to: { name: 'suivi-des-ic-form', params: { id: this.$route.params.id } } } : {}
    ])
    this.$store.commit('pages/setPageTitle', this.$t('processes.title.suivi_des_ic'))
    if (this.$route.params.id) {
      this.formData.id = this.$route.params.id
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (currentRoute.params.id) {
      store.dispatch('processes/fetchProcess', { id: currentRoute.params.id, type: 'suiviIc' }).then(() => {
        store.dispatch('processes/fetchFormFields', 'suiviIc')
      })
    } else {
      store.dispatch('processes/fetchFormFields', 'suiviIc')
    }
  },
  beforeDestroy () {
    this.$store.commit('processes/setCurrentProcess', null)
  },
  methods: {
    changeComputedAmount (model) {
      switch (model) {
        case 'Iard':
          this.computedAmountIard = this.getComputedAmount(0)
          break
        case 'Sante':
          this.computedAmountSante = this.getComputedAmount(1)
          break
        case 'Ic_vie':
          this.computedAmountIc_vie = this.getComputedAmount(2)
          break
      }
    },
    addMontant (model) {
      let modelMappedToDomaine = model.toUpperCase()
      let data = {
        suivi_ic_id: this.$route.params.id,
        domaine: modelMappedToDomaine,
        classique: this[`icClassique${model}Amount`],
        complementaire: this[`icComplementaire${model}Amount`],
        date: this[`icDate${model}`],
        accepte_agent: this[`icAcceptAgent${model}`] ? this[`icAcceptAgent${model}`].value : null
      }

      if (!data.date) {
        return this.$q.notify({
          message: 'Veuillez entrer une date',
          color: 'negative'
        })
      }
      this.$store.dispatch('processes/addMontantPropose', data).then((response) => {
        this.notifySuccess('processes.success')
        data.id = response.id
        this.montantProposes.push(data)
        this[`icClassique${model}Amount`] = null
        this[`icComplementaire${model}Amount`] = null
        this[`icDate${model}`] = null
        this[`icAcceptAgent${model}`] = null
      })
    },
    removeMontant (model, id) {
      this.$store.dispatch('processes/removeMontantPropose', id).then((response) => {
        this.$q.notify({
          message: 'Montant proposé supprimé avec succès',
          color: 'positive'
        })
        this.montantProposes = this.montantProposes.filter(montant => montant.id !== id)
      })
    },
    getComputedAmount (id) {
      let icClassiqueAmount = this.formData.data_montant[id].classique ? this.formData.data_montant[id].classique : 0
      let icComplementaireAmount = this.formData.data_montant[id].complementaire ? this.formData.data_montant[id].complementaire : 0

      let amount = parseInt(icClassiqueAmount) + parseInt(icComplementaireAmount)
      return amount !== 0 ? amount.toLocaleString() : null
    },
    updateWallet (wallets) {
      console.log(wallets)
      let walletMapped = wallets.map(wallet => ({
        model_type: 'wallet',
        model_id: wallet.id
      }))

      if (!this.formData.related_objects) this.formData.related_objects = []
      // Remove all wallet in related object
      this.formData.related_objects = this.formData.related_objects.filter(related => related.model_type !== 'wallet')
      this.formData.related_objects.push(...walletMapped)
    },

    onSelectLinkedObj (obj) {
      console.log(obj)
      this.linkedAgent = {
        label: obj.value.label,
        id: obj.value.value,
        actions: [
          { label: this.$t('tasks.remove_linked_object'), payload: { name: 'model-detach', params: { id: obj.value.value } } }
        ]
      }
    },
    finishProcess () {
      this.formData.statut = { label: 'TERMINE', value: 'TERMINE' }
      this.save()
    },
    onMenuClick ({ name, params }) {
      this.hasChanged = true
      if (name === 'model-detach') {
        this.linkedAgent = null
        this.searchBoxActivated = false
      }
    },
    toggleCollapsible (event) {
      let originalElement = event.target || event.srcElement || event.originalTarget
      if (originalElement.classList.contains('arrow')) {
        originalElement.parentElement.classList.toggle('rotate')
        originalElement.parentElement.nextSibling.classList.toggle('display-none')
      } else {
        originalElement.classList.toggle('rotate')
        originalElement.nextSibling.classList.toggle('display-none')
      }
    },
    onSave ({ id }) {
      this.isUpdate = true
      if (!this.$route.params.id) {
        this.$router.push({ name: 'suivi-des-ic-form', params: { id } })

        this.$store.commit('pages/setTabs', [
          { name: 'suivi-des-ic-form', label: 'Informations', to: { name: 'suivi-des-ic-form', params: { id: id } } },
          { name: 'echeance-fiche', label: 'échéances & paiements', to: { name: 'echeance-fiche', params: { suiviDesIcId: id } } }
        ])
      }
    },
    destroy () {
      this.$confirm(this.$t('processes.delete_process_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('processes/deleteProcesses', { type: 'suiviIc', id: this.$route.params.id }).then(() => {
          this.notifySuccess('processes.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .processColor
    color $process
  .bloc-item
    position relative
  .toggler-collapse
    border 1px solid $process
    border-radius 100%
    width 20px
    height 20px
    position absolute
    right 0
    top 7px
    cursor pointer
  .arrow
    border-top 1px solid $process
    border-left 1px solid $process
    transform rotate(45deg)
    width 6px
    height 6px
    margin-left 6px
    margin-top 7px
  .display-none
    display none
  .rotate
    transform rotate(180deg)
  ul
    list-style: none
    padding: 0
    li
      margin-bottom: 10px
  .button-create-sujet-commit
    height 35px
</style>
