<template>
  <div class="wallets-list col">
    <h6 v-if="showTitle">{{$t('wallets.wallets')}}</h6>

    <div class="row q-col-gutter-md">
      <div :class="`col ${collSizeWallet}`" v-for="(item, index) in cardItems" v-bind:key="item.id">
        <WalletCard :wallet="item" v-model="value[index]" @menuClick="onMenuClick" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { WalletCard } from './'

export default {
  name: 'WalletsList',
  components: { WalletCard },
  props: {
    wallets: { type: Array, default: () => ([]) },
    value: { type: Array, default: () => ([]) },
    showTitle: { type: Boolean, default: true },
    collSizeWallet: { type: String, default: 'col-6' }
  },

  computed: {
    ...mapGetters({
      favorites: 'favorites/getFavorites'
    }),

    cardItems () {
      return this.wallets.map(wallet => ({
        ...wallet,
        label: wallet['NOMPTF'],
        actions: wallet['value.actions'] ? wallet['value.actions'] : [
          { label: this.$t('tasks.create_task'), payload: { name: 'task-create', params: { model_type: 'wallet', model_object: wallet } } }
        ],
        is_favorite: !!this.favorites && !!this.favorites['wallet'] &&
          this.favorites['wallet'].some(favorite => favorite.id === wallet.id),
        model: { model_type: 'wallet', model_id: wallet.id }
      }))
    }
  },

  methods: {
    onMenuClick ({ name, params }) {
      console.log(params)
      if (name === 'model-detach') this.$emit('model-detach', params.id)
      else this.$router.push({ name, params })
    }
  }
}
</script>

<style>
</style>
