<template>
  <div class="row q-gutter-md full-width">
    <div class="column col-6" v-if="linkedObjs.length">
      <base-card v-bind="cible" draggable @menuClick="onMenuClick">
        <q-avatar slot='avatar' size="3rem" color="per" icon="icon-icons_souscible" />

        <q-item class="items-start q-pa-none">
          <q-item-section v-if="cible">
            <q-item-label caption>{{$formatDate(cible.date)}}</q-item-label>
            <q-item-label>
              <strong><router-link :to="{ name: 'sous-cible-show', params: { id: cible.id } }">{{$t(`sousCibles.fields.type.options.${cible.type}`)}}</router-link></strong>
            </q-item-label>

            <div class="row">
              <div class="col" v-for="obj in cible.linkedObjects" :key="obj.id">
                <q-item class="q-pa-none">
                  <q-item-section avatar>
                    <q-avatar :icon="obj.icon.name" size="2rem" :color="obj.icon.color" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label caption>{{obj.label}}</q-item-label>
                  </q-item-section>
                </q-item>
              </div>
            </div>
          </q-item-section>
        </q-item>
      </base-card>
    </div>

    <div class="add-process-card q-ma-none q-mt-sm col-6">
      <search-box v-if="searchBoxActivated"
        @input="onSelectLinkedObj"
        model_type="depart"
        :label="$t('processes.fields.process.label')"
        :params="{ 'with_virtual_objects': true, 'with_deleted_objects': true }"
        :color="color" />
      <q-btn icon="add_circle" v-else :color="color" class="full-height full-width" size="md" outline @click="addObject">
        {{$t('processes.link_process')}}
      </q-btn>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },

  data () {
    return {
      searchBoxActivated: false,
      linkedObjs: []
    }
  },
  props: ['color'],
  methods: {
    detachProcess (processId) {
      this.linkedObjs = this.linkedObjs.filter(obj => obj.id !== processId)
      this.$emit('processUpdated', this.linkedObjs)
    },
    onSelectLinkedObj (obj) {
      console.log(obj)
      this.searchBoxActivated = false
      this.linkedObjs.push({
        id: obj.value.entity_id,
        NOMPTF: obj.value.label,
        'value.actions': [
          { label: this.$t('tasks.remove_linked_object'), payload: { name: 'model-detach', params: { id: obj.value.entity_id } } }
        ]
      })
      this.$emit('walletsUpdated', this.linkedObjs)
    },
    activateSearchBox () {
      this.searchBoxActivated = true
    },
    addObject () {
      this.activateSearchBox()
    }
  }
}
</script>

<style lang="stylus" scoped>
  .add-process-card
    height 125px
</style>
