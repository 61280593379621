<template>
  <div class="agents-list">
    <h6>{{$t('agents.agents')}}</h6>

    <div class="row q-col-gutter-md">
      <div class="col col-6" v-for="item in cardItems" v-bind:key="item.id">
        <AgentCard :agent="item" @menuClick="onMenuClick" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AgentCard } from './'

export default {
  name: 'AgentsList',
  props: ['agents'],
  components: { AgentCard },

  computed: {
    ...mapGetters({
      favorites: 'favorites/getFavorites'
    }),

    cardItems () {
      return this.agents.map(agent => ({
        ...agent,
        label: agent['TITULAIRE'],
        actions: [{
          label: this.$t('tasks.create_task'),
          payload: { name: 'task-create', params: { model_type: 'agent', model_object: agent } }
        }],
        is_favorite: !!this.favorites && !!this.favorites['agent'] &&
          this.favorites['agent'].some(favorite => favorite.id === agent.id),
        model: { model_type: 'agent', model_id: agent.id }
      }))
    }
  },

  methods: {
    onMenuClick ({ name, params }) {
      this.$router.push({ name, params })
    }
  }
}
</script>

<style>
</style>
