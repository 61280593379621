<template>
  <base-card v-bind="walletDetail" :fav_candidate="true" v-on="$listeners">
    <q-avatar slot='avatar' size="3rem" color="reseau" text-color="white" icon="account_balance_wallet" />

    <template slot='default'>
      <div class="row justify-between items-start">
        <q-item-label><strong>{{walletDetail.label}}</strong></q-item-label>
        <q-badge slot='indicators' v-if="walletDetail['TYPE_RGT'] === 'PDV'" color="grey-4 text-default">PDV</q-badge>
      </div>
      <div class="row q-mt-xs q-mb-md">
        <q-item-label>{{ walletDetail.CODEICX }}</q-item-label>
      </div>

      <div class="row" v-if="value">
        <div class="col">
          <q-item-label caption>{{$t('wallets.comm_totale')}}</q-item-label>
          <q-item-label>{{comm_totale}}</q-item-label>
        </div>
        <div class="col">
          <div v-if="!isRPPR && !isAdmin">
            <q-item-label caption>{{$t('wallets.comm_ajustee')}}</q-item-label>
            <q-item-label>{{`${Math.round(Number(walletDetail.comm_ajustee) / 1000)} K€`}}</q-item-label>
          </div>
          <div v-else>
            <q-item-label caption>{{$t('wallets.comm_ajustee')}}</q-item-label>
            <base-input-text dense suffix="€" v-model="value.comm_ajustee" />
          </div>
        </div>
      </div>

      <div class="row" v-if="value">
        <div class="col col-6 offset-6">
          <div v-if="!isRPPR && !isAdmin">
            <q-item-label caption>{{$t('wallets.mdt_gest')}}</q-item-label>
            <q-item-label>{{walletDetail.mdt_gest ? `${Number(walletDetail.mdt_gest)} %` : '-'}}</q-item-label>
          </div>
          <div v-else>
            <q-item-label caption>{{$t('wallets.mdt_gest')}}</q-item-label>
            <base-input-text dense suffix="%" v-model="value.mdt_gest" />
          </div>
        </div>
      </div>
    </template>
  </base-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WalletCard',
  props: ['wallet', 'value', 'model'],

  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser',
      isAdmin: 'auth/isAdmin'
    }),

    walletDetail () {
      return this.wallet || this.model
    },

    isRPPR () {
      return this.currentUser.role === 'RPPR'
    },

    comm_totale () {
      const comm_tot_ia = Number(this.walletDetail['MT_COMM_TOT_IA_N1'])
      const comm_tot_vs = Number(this.walletDetail['MT_COMM_TOT_VS_N1'])
      return `${Math.round((comm_tot_ia + comm_tot_vs) / 1000)} K€`
    }
  }
}
</script>

<style>
</style>
