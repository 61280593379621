<template>
  <base-card :showSeparator="showSeparator" v-bind="agent" :fav_candidate="true" v-on="$listeners">
    <q-avatar v-if="agent['PHOTO']" slot='avatar' size="3rem"><q-img :src="agent['PHOTO']" :ratio="1" /></q-avatar>
    <q-avatar v-else slot='avatar' size="3rem" color="reseau" text-color="white" :icon="icon" />

    <template slot='default'>
      <q-item-label class="q-mb-md"><strong>{{agent.label}}</strong></q-item-label>
      <div class="row justify-between items-end">
        <q-item-label caption>{{`${agentAge} ${$t('agents.years')}`}}</q-item-label>
        <q-item-label caption>{{$t('agents.date_agent')}} {{agentDate}}</q-item-label>
      </div>
    </template>
  </base-card>
</template>

<script>
import { date } from 'quasar'
const { getDateDiff } = date

export default {
  name: 'AgentCard',
  props: {
    'agent': { type: Object, required: true },
    'icon': { type: String, default: 'avatar' },
    'showSeparator': { type: Boolean, default: true }
  },

  computed: {
    agentAge () {
      return this.agent['DATE_NAIS'] ? Math.floor(getDateDiff(new Date(), new Date(this.agent['DATE_NAIS']), 'days') / 365) : '-'
    },

    agentDate () {
      return this.agent['DATE_AGENT'] ? this.$formatDate(new Date(this.agent['DATE_AGENT']), 'MM/YYYY') : '-'
    }
  },

  methods: {
    menuClick ({ name, params }) {
      this.$router.push({ name, params })
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
